<template>
  <span class="strapi-image">
    <strapi-image v-bind="$props" :class="{ rounded }" />
    <img
      v-if="image.overlay && !ignoreOverlay"
      ref="imageOverlay"
      :class="imgCSSOverlay"
      :alt="image.overlay.alternativeText || 'Overlay'"
      :src="image.overlay.url"
    />
  </span>
</template>

<script lang="ts">
import type { StrapiImage } from '~/apollo/types/types';

export default defineComponent({
  name: 'StrapiImageOverlay',
  props: {
    image: {
      type: Object as PropType<StrapiImage>,
      required: true,
    },
    forceWidth: {
      type: Number,
      default: undefined,
    },
    forceHeight: {
      type: Number,
      default: undefined,
    },
    alt: {
      type: String,
      default: undefined,
    },
    altFallback: {
      type: String,
      default: undefined,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    autoScale: {
      type: Boolean,
      default: false,
    },
    imageClass: {
      type: String,
      default: undefined,
    },
    overlayClass: {
      type: String,
      default: undefined,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    /**
     * This class is used to show a background in a properly sized container with specified widths and heights or when the height is unknown.
     * object-fit: cover and positioning ensures that our image is placed correctly
     * within the container and not showing any empty space.
     * Can get into conflict with `fluid` prop, so use either one of them depending on the use case
     */
    asBackground: {
      type: Boolean,
      default: false,
    },
    ignoreOverlay: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const imgCSSOverlay = computed<Array<string>>(() => {
      const cssClasses = props.overlayClass ? [props.overlayClass] : [];
      cssClasses.push('position-absolute top-0 start-0 object-cover');

      if (props.asBackground) {
        cssClasses.push('h-100 w-100 z-[-1]');
      }

      if (props.rounded) {
        cssClasses.push('rounded');
      }

      return cssClasses;
    });

    return {
      imgCSSOverlay,
    };
  },
});
</script>
